html {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    background: #000;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Space Mono', monospace;
    margin: 0;
}

ul,
li {
    padding: 0;
    margin: 0;
}

ul {
    list-style: none;
}
li {
    display: inline-block;
}

* {
    box-sizing: border-box;
}
